@font-face {
  font-family: "Saira Condensed Condensed";
  src: url("./assets/fonts/SairaCondensed-Bold.woff2") format("woff2"),
    url("./assets/fonts/SairaCondensed-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Saira Condensed";
  src: url("./assets/fonts/SairaCondensed-Regular.woff2") format("woff2"),
    url("./assets/fonts/SairaCondensed-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Saira Condensed";
  src: url("./assets/fonts/SairaCondensed-Light.woff2") format("woff2"),
    url("./assets/fonts/SairaCondensed-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Saira Condensed";
  src: url("./assets/fonts/SairaCondensed-SemiBold.woff2") format("woff2"),
    url("./assets/fonts/SairaCondensed-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Saira Condensed";
  src: url("./assets/fonts/SairaCondensed-Medium.woff2") format("woff2"),
    url("./assets/fonts/SairaCondensed-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Saira Condensed";
  src: url("./assets/fonts/SairaCondensed-Light.woff2") format("woff2"),
    url("./assets/fonts/SairaCondensed-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Saira Condensed";
  src: url("./assets/fonts/SairaCondensed-Regular.woff2") format("woff2"),
    url("./assets/fonts/SairaCondensed-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Saira Condensed";
  src: url("./assets/fonts/SairaCondensed-Medium.woff2") format("woff2"),
    url("./assets/fonts/SairaCondensed-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Saira Condensed";
  src: url("./assets/fonts/SairaCondensed-SemiBold.woff2") format("woff2"),
    url("./assets/fonts/SairaCondensed-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Saira Condensed";
}

body {
  overflow: auto !important;
  padding: 0 !important;
}

.btn-check:focus + .btn,
.btn:focus {
  outline: 0;
  box-shadow: none !important;
}

input:focus-visible {
  border: none;
  outline: none;
  box-shadow: none;
}

.spinner-border {
  position: absolute;
  left: 50%;
  top: 50%;
  margin-top: 16px;
  margin-bottom: 16px;
  /* transform: translate(-50%,-50%); */
}

.map-spinner {
  background-color: #ec7c24;
}

#loadSpin {
  top: 50%;
  left: 50% !important;
  margin-top: 16px;
  margin-left: 16px;
}

.login .spinner-border {
  position: absolute;
  left: 47.8%;
  top: 50%;
}

.navbar {
  display: flex;
  flex-wrap: nowrap !important;
  position: fixed !important;
  width: 100%;
  z-index: 99;
  background: rgb(17, 1, 50);
  background: linear-gradient(
    90deg,
    rgba(17, 1, 50, 1) 0%,
    rgba(59, 32, 96, 1) 100%
  );
}

.navbar .user {
  padding: 0 12px 0 0;
}

.navbar .toggle-sidebar {
  color: #fff;
  border: none;
  outline: none;
  background-color: transparent !important;
  font-size: 30px;
  font-weight: bold;
  box-shadow: none !important;
}

.margin-left {
  margin-left: 0 !important;
}

.navbar .logo {
  width: fit-content;
}

.navbar .shoping-def {
  margin-left: 30px;
}
.country-select .modal-header .btn-close {
  position: absolute;
  top: 12px;
  left: 14px;
}
.shoping-def-sub .shoping-btns {
  flex-direction: column;
}

.navbar .shoping-def .shoping-btns .area {
  background-color: #92f8ff !important;
  box-shadow: none !important;
  border: none;
  color: #000;
}

.modal-body {
  padding: 0 !important;
}

.shoping-btns .modal-header {
  display: flex;
  flex-direction: column;
  padding: 13px 9px 0;
}

#area .modal-dialog .modal-content {
  background-color: #92f8ff;
  padding: 0 !important;
  max-width: 299px;
  width: 100%;
}

#area .modal-dialog .modal-body {
  max-height: 562px;
  overflow: auto;
  max-width: 299px;
  width: 100%;
}

#area .modal-body {
  padding: 0;
}

#area .modal-body p {
  font-size: 22px;
  line-height: 20px;
  font-weight: 700;
  margin: 0 10px 0 49px;
  color: #000;
  cursor: pointer;
}

#area .modal-body p:hover {
  color: #3f1da1;
}

#area hr {
  margin: 1.1rem 0;
  background-color: #c4c4c4;
}

#area .modal-dialog.area-dialog {
  left: 1.8rem !important;
}

/* mobile view */

#m-area .modal-dialog .modal-content {
  background-color: #92f8ff;
  padding: 0 !important;
}

#m-area .modal-dialog .modal-body {
  max-height: 562px;
  height: fit-content;
  overflow: auto;
  width: 100%;
}

#m-area .modal-dialog {
  top: 2.5rem;
}

#m-area .modal-body {
  padding: 0;
}

#m-area .modal-body p {
  font-size: 22px;
  line-height: 20px;
  font-weight: 700;
  margin: 0 10px 0 49px;
  color: #000;
  cursor: pointer;
}

#m-area .modal-body p:hover {
  color: #3f1da1;
}

#m-area form input {
  height: 30px;
  font-size: 15px;
  padding-left: 10px;
  color: #404040;
  font-weight: 600 !important;
  line-height: 22px !important;
}

#m-area hr {
  margin: 1.1rem 0;
  background-color: #c4c4c4;
}

#seller .modal-dialog.seller-dialog {
  left: 11rem;
}

#seller .modal-dialog.nav-seller-dialog {
  left: 7.7rem;
}

.modal-dialog.seller-dialog form,
.modal-dialog.nav-seller-dialog form {
  margin-bottom: 10px;
}

#seller .modal-dialog .modal-content {
  background-color: #db73ff;
  padding: 0 !important;
  max-width: 299px;
  width: 100%;
}

#seller .modal-dialog .modal-body {
  max-height: 438px;
  overflow: auto;
  max-width: 299px;
  width: 100%;
}

.seller_content {
  display: flex;
  margin-bottom: 3px;
  padding: 0 1rem;
}

.seller_content:hover {
  background: rgba(255, 255, 255, 0.25);
}

.seller_content .seller_imag {
  width: 77px;
  height: 55px;
  border-radius: 5px;
  background: #fff;
  margin-right: 10px;
}

.seller_content .seller_imag img {
  padding: 4px 14px;
  box-sizing: border-box;
  width: 100%;
  height: -webkit-fill-available;
}

.seller_content .content_detail h6 {
  font-size: 16px;
  line-height: 20px;
  font-weight: 700 !important;
  text-transform: uppercase;
  max-width: 161px;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #000;
  margin: 0;
}

.seller_content .content_detail .address {
  font-size: 14px;
  line-height: 14px;
  font-weight: 500 !important;
  color: #525f6f;
  max-width: 162px;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
}

/* mobile view */

#m-seller .modal-dialog .modal-content {
  background-color: #db73ff;
  padding: 0 !important;
}

#m-seller .modal-dialog.seller-dialog {
  top: 2.5rem;
}

#m-seller .modal-dialog .modal-body {
  max-height: 438px;
  overflow: auto;
  width: 100%;
}

#m-seller form input {
  height: 30px;
  font-size: 15px;
  padding-left: 10px;
  margin-bottom: 10px;
  color: #404040;
  font-weight: 600 !important;
  font-size: 15px;
  line-height: 22px !important;
}

/* end mobile view */

#tags .modal-body {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

#tags .modal-dialog .modal-content {
  background-color: #ffce50;
  padding: 0 !important;
  padding-bottom: 0.8rem !important;
  /* max-height: 191px;
    overflow: scroll;
    max-width: 463px; */
  width: 100%;
}

#tags .modal-dialog .modal-content form {
  display: flex;
  justify-content: center;
}

#tags .modal-dialog .modal-content form input {
  max-width: 284px;
  width: 100%;
  margin: 0 auto;
  padding-left: 10px;
  color: #404040;
  font-weight: 600 !important;
  font-size: 14px;
  line-height: 22px !important;
}

#tags .modal-body .tag-btn {
  padding: 8px 21px;
  margin: 9.5px 3.72px 0 !important;
  background-color: #e8e8e8;
  color: #828282;
}

/* #tags .modal-body .tag-btn:hover{
    color: #E8E8E8 ;
    background-color: #828282;
} */

#tags .modal-body .selected {
  color: #e8e8e8;
  background-color: #828282;
}

#tags .modal-body button p {
  font-size: 12px;
  line-height: 11.35px;
  margin: 0;
  color: unset;
  text-transform: uppercase;
}

#tags .modal-dialog.nav-tag-dialog {
  left: 14.4rem !important;
}

/* m-view of tag */

#m-tags .modal-body {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

#m-tags .modal-dialog .modal-content {
  background-color: #ffce50;
  padding: 0 !important;
  padding-bottom: 0.8rem !important;
  /* max-height: 191px;
    overflow: scroll;
    max-width: 463px; */
  width: 100%;
}

#m-tags .modal-dialog.tag-dialog {
  top: 2.5rem;
}

#m-tags .modal-dialog .modal-content form {
  display: flex;
  justify-content: center;
}

#m-tags .modal-dialog .modal-content form input {
  max-width: 284px;
  width: 100%;
  color: #404040;
  font-weight: 600 !important;
  font-size: 15px;
  line-height: 22px !important;
  margin: 0 auto;
  height: 30px;
  font-size: 15px;
  padding-left: 10px;
}

#m-tags .modal-body .tag-btn {
  padding: 8px 21px;
  margin: 9.5px 3.72px 0 !important;
  background-color: #e8e8e8;
  color: #828282;
}

/* #m-tags .modal-body .tag-btn:hover{
    background-color: #828282;
    color: #E8E8E8;
} */

#m-tags .modal-body .selected {
  color: #e8e8e8;
  background-color: #828282;
}

#m-tags .modal-body button p {
  font-size: 12px;
  line-height: 11.35px;
  margin: 0;
  color: unset;
  text-transform: uppercase;
}

/* end 0f m-view */

.shoping-btns .modal-title {
  font-size: 26px;
  line-height: 20px;
  font-weight: 700;
  color: #404040;
  margin-bottom: 15px;
  text-transform: uppercase;
}

.shoping-btns form {
  width: 100%;
}

.shoping-btns input {
  width: 100%;
  background: #ffffff;
  color: #404040;
  font-weight: 600 !important;
  font-size: 15px;
  text-transform: uppercase;
  line-height: 22px !important;
  border: 0.5px solid #404040 !important;
  box-sizing: border-box;
  border-radius: 7.95455px !important;
  padding-left: 10px;
}

.navbar .modal-body h1,
.shoping-btns .modal-body h1 {
  text-align: center !important;
  font-size: 1.5rem;
}

.shoping-def-sub .shoping-img img {
  width: 30px;
  height: 35px;
}

.shoping-def-sub .sub-menu-btn button {
  background-color: transparent !important;
  border: none;
  outline: none;
  box-shadow: none !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 10px;
  margin-top: 10px;
}

.shoping h6 {
  white-space: nowrap;
}

.shoping {
  column-gap: 10px;
}

.pink-btn {
  background-color: #db73ff !important;
  box-shadow: none !important;
  border: none !important;
  color: #000 !important;
}

.yellow-btn {
  background-color: #ffce50 !important;
  box-shadow: none !important;
  border: none !important;
  color: #000 !important;
}

.navbar .shoping-btns {
  column-gap: 8px;
}

.navbar h6,
.navbar img {
  cursor: pointer;
}

.navbar .user button {
  background-color: transparent !important;
  border: none;
  outline: none;
  box-shadow: none !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 14px;
}

.navbar .user-def {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.navbar .user button .language {
  border-radius: 50%;
}

.navbar .search_bar {
  border-radius: 50px;
  border: none;
  padding: 5px 10px;
  outline: none;
}

.flex-column {
  flex-direction: column;
}

.navbar .user .user_img {
  width: 50px;
  height: 50px;
  /* margin-top: 20px; */
  border: 2px solid #8292a6;
  /* #5500A5 */
  border-radius: 50%;
  cursor: pointer;
}

.user {
  align-items: center;
}

.shoping-def h6 {
  color: #fff;
  font-weight: normal !important;
}

.navbar .right-toggle {
  color: #fff;
  border: none;
  outline: none;
  background-color: transparent !important;
  font-size: 30px;
  font-weight: bold;
  box-shadow: none !important;
  display: none !important;
  position: relative;
}

.navbar .sub-box {
  position: absolute;
  left: -180px;
  padding: 15px;
  background-color: #14003d;
  border-radius: 15px;
}

.phone-search {
  width: 100%;
  display: none;
}

.phone-search input {
  width: 100%;
  padding: 5px 20px;
  border-radius: 50px;
  border: none;
  outline: none;
}

/* Sidebar */

.sidebar {
  width: 200px;
  height: 100vh;
  background-color: #e5e5e5;
  position: fixed;
  z-index: 1;
  margin-top: 117px;
}

.sidebar .price ul li input {
  position: relative;
  appearance: none;
}

.sidebar .price ul li span {
  margin-left: 20px;
}

.sidebar .price ul li input::before {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 5px;
  border: 1px solid lightgray;
  top: -10px;
  right: -19px;
  background-color: #fff;
  box-shadow: inset 0 0 4px gray;
  box-shadow: inset 0 0 6px lightgrey;
}

.sidebar .price ul li input::after {
  content: "";
  position: absolute;
  border: solid #2a134e;
  border-width: 0 3px 3px 0;
  height: 12px;
  left: 6px;
  position: absolute;
  top: -7px;
  transform: rotate(45deg);
  width: 7px;
  display: none;
}

.sidebar .price ul li input:checked::after {
  display: block;
}

.sidebar ul,
.price ul {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-top: 20px;
}

.sidebar ul li {
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 10px;
  color: #000;
}

.sidebar ul li.active {
  color: #562a8c;
}

.sidebar-menu ul li {
  cursor: pointer;
}

.price {
  margin-top: 20px;
}

.sidebar .price h3 {
  color: #562a8c;
}

.close-btn button {
  background-color: transparent;
  border: none;
  outline: none;
}

.price-header {
  color: #562a8c;
  font-weight: 600;
  font-size: 22px;
}

.price ul li {
  font-size: 20px;
  display: flex;
  align-items: center;
  column-gap: 10px;
  color: rgb(10, 10, 10);
  margin-bottom: 10px;
  font-size: 18px;
}

/* main-content */

.main-content {
  margin-left: 200px;
  column-count: 4;
  column-gap: 14px;
  margin-top: 116px;
}

.main-content.nodata-content {
  column-count: auto;
}

.main-body {
  background-color: #efeee4;
  justify-content: space-between;
}

.main-toggle {
  height: fit-content;
  margin-top: 130px;
}

.main-toggle button {
  border: none;
  background-color: transparent;
  outline: none;
}

.main-content .product-box {
  border-radius: 5px;
  /* box-shadow: 0 0 10px lightgray; */
  background-color: #fff;
  width: 100%;
  overflow: hidden;
  cursor: pointer;
}

.gap {
  column-gap: 40px;
}

.main-content .product-box img {
  width: 100%;
}

.product-def h5 {
  color: #562a8c;
}

.product-def {
  position: relative;
}

.mc-icon {
  position: absolute;
  right: 20px;
  top: -30px;
}

.mc-icon img {
  width: 50px !important;
}

.product-per {
  color: gray;
  font-weight: 500;
}

.product-img > span {
  display: block !important;
}

figure img {
  max-width: 100%;
  display: block;
}

figure {
  margin: 0;
  display: grid;
  grid-template-rows: 1fr auto;
  margin-bottom: 10px;
  break-inside: avoid;
}

figure > img {
  grid-row: 1 / -1;
  grid-column: 1;
}

figure a {
  color: black;
  text-decoration: none;
}

figcaption {
  grid-row: 2;
  grid-column: 1;
  background-color: rgba(255, 255, 255, 0.5);
  padding: 0.2em 0.5em;
  justify-self: start;
}

.container {
  column-count: 4;
  column-gap: 10px;
}

/* offers */
.fav-img {
  overflow: auto;
}
.fav-img img {
  height: 53px;
  width: 52px;
  border-radius: 50%;
  margin-right: 5px;
}

.offers-main {
  width: calc(100% - 200px);
  margin-top: 116px;
  margin-left: 200px;
  padding: 0 0 1rem;
}
.offers-main.map-style {
  padding-bottom: 0 !important;
}
.offers-main .offers-header h1 {
  /* margin-top: 38px; */
  color: #5f5f5f;
}

.pink {
  color: #ff00b4 !important;
}

.orange {
  color: #ffae00 !important;
}

.pupular-offers-header h4 {
  color: #5500a5;
}

.pupular-offers-header img {
  width: 25px;
}

.offers-box h5 {
  color: #5500a5;
  font-weight: 600;
}

.offers-box .offers-images {
  width: 100%;
  width: 230px !important;
  height: 230px !important;
  margin: 5px 5px 0 5px;
  cursor: pointer;
}

.offers-box img {
  width: 100%;
  height: 100% !important;
  border-radius: 8px;
  object-fit: cover;
  border: 2px solid #5500a5;
}

.no_data {
  text-align: center;
  width: 100%;
  height: 10vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.no_data h3 {
  /* margin:0px; */
  padding: 0;
}

.main-deal {
  display: flex;
  padding: 14px;
  background: linear-gradient(101.82deg, #14003d 35.1%, #563085 100%);
  border: 0.646739px solid #3f1da1;
  box-sizing: border-box;
  border-radius: 12.9348px;
}
.main-deal .deal_img {
  height: 106px;
  width: 148px;
  padding: 0;
  background-color: #fff;
  border-radius: 5px;
  margin-right: 20px;
}
.main-deal .deal_img img {
  height: 100%;
  width: 100%;
  border-radius: 5px;
}
.main-deal .deal-detail h6 {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #fff;
  letter-spacing: 0.00214272px;
  text-transform: uppercase;
}
.main-deal .deal-detail p.detail-text {
width: 150px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.main-deal .deal-detail p {
  font-size: 14px;
  line-height: 14px;
  font-weight: 200 !important;
  color: #fff;
  max-width: 162px;
  margin: 0 0 5px;
}
.deal-voucher {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  margin: 30px 0 20px 0;
}
.deal-voucher .deal-header {
  color: gray;
}
.deal-voucher .deal-header button {
  border: none;
  background-color: transparent !important;
  box-shadow: none !important;
}
.deal-tags {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.deal-tags .btnTag input {
  position: absolute;
  width: 100%;
  /* margin: 0px 3.72px 3.72px !important; */
}
.deal-tags.tag-select {
  font-size: 12px;
  line-height: 11.35px;
  text-transform: uppercase;
  display: inline-block;
  font-weight: 600;
  cursor: pointer;
  border-radius: 12px;
  padding: 8px 21px;
  margin: 9.5px 3.72px 0 !important;
  background-color: #e8e8e8;
  color: #828282;
}

.deal-tags .tag-select:hover {
  background: #d0d3d4;
}

.deal-header h4 {
  font-weight: 700;
  font-size: 26px;
  line-height: 31.2px;
}
.deal-voucher .deal {
  color: #5500a5;
}
.default-img {
  display: none;
}
.deal .active-img {
  display: none;
}
.default-img,
.active-img {
  width: 30px;
}
.deal-header h4 {
  display: flex;
  align-items: center;
}
.deal .default-img {
  display: block;
}
.offers-left .popular-offers {
  border-bottom: 1px solid lightgray;
}

.pupular-offers-header button {
  border: none;
  background-color: transparent !important;
  box-shadow: none !important;
}

.left-vouchers {
  border-right: 1px solid lightgray;
  padding-right: 20px;
}

.left-vouchers > div {
  margin-bottom: 25px;
}

.even_data {
  margin-bottom: 25px;
}

.share-btn img {
  height: auto;
  max-width: 120px;
}

.voucher-box {
  column-gap: 20px;
}
.vouchers-main .left-vouchers .voucher-box,.vouchers-main .even_data .voucher-box{
  cursor: pointer;
}

.voucher-box .v-img {
  /* width: 200px !important; */
  height: 170px;
  object-fit: cover;
  border-radius: 11px;
}

.voucher-text {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.voucher-text h5{
  font-size: 22px;
  font-weight: 500;
}
.voucher-text .discountamount h5 {
  color: #525F6F;
  font-weight: 700;
  font-size: 22px;
  letter-spacing: 1px;
  margin: 10px 0 2px;
}

.voucher-text p {
  font-size: 12px;
  /* word-break: break-all; */
  line-height: 14px;
  color: #5e5e5e;
}

.voucher-btn {
  margin-left: auto;
  white-space: nowrap;
}

.voucher-btn .share,
.voucher-btn .get {
  background-color: #5500a5 !important;
  color: #fff;
  border: none;
  outline: none;
  box-shadow: 2.95549px 2.95549px 4.92581px 1.47774px rgba(0, 0, 0, 0.15);
  border-radius: 50px;
  padding: 7px 20px;
}

.voucher-btn .get {
  background-color: #ec7c24 !important;
  margin-left: 10px;
}

.voucher-code {
  border: 2px solid #f1f3f4;
  width: fit-content;
  display: flex;
  align-items: center;
  border-radius: 5px;
  overflow: hidden;
  margin-left: 28px;
  height: 60px;
  justify-content: space-between;
}

.voucher-code input {
  background-color: transparent;
  border: none;
  padding: 0 20px;
  outline: none;
  height: 50px;
  height: 100%;
  height: 60px !important;
}

.voucher-code button {
  background-color: #ec7c24;
  color: #fff;
  border: none;
  padding: 10px 26px;
  outline: none;
  height: 50px;
  font-weight: 500;
  height: 100%;
  font-size: 18px;
  height: 60px !important;
}

.voucher-list {
  width: 300px;
  max-width: 100%;
  font-weight: 600;
  color: #262626;
  margin-left: auto;
  margin-right: 150px;
}

.voucher-list .per-list {
  padding-bottom: 20px;
  border-bottom: 2px solid #f6f7f8;
  font-size: 18px;
}

.voucher-list .total-per span {
  font-size: 30px;
}

.voucher-list button {
  width: 100%;
  background-color: #ec7c24;
  color: #fff;
  border: none;
  padding: 10px;
  outline: none;
  border-radius: 5px;
  margin-top: 10px;
  font-weight: 500;
  font-size: 18px;
}

.your_cart {
  cursor: pointer;
}

.your_cart,
.cash,
.social {
  padding: 15px;
  border-radius: 20px;
  background: rgb(17, 1, 50);
  background: linear-gradient(
    90deg,
    rgba(17, 1, 50, 1) 0%,
    rgba(59, 32, 96, 1) 100%
  );
}
.your_cart h4 {
  cursor: pointer !important;
  color: #fff;
  font-weight: 400 !important;
}

.your_cart span,
.cash span {
  color: #a8ffff;
  font-size: 50px;
  display: flex;
  font-weight: 600;
  align-items: baseline;
}

.your_cart span p {
  color: #a8ffff;
  font-size: 37px;
  margin: 0;
  padding: 0;
}

.your_cart .cart_img {
  height: 41px;
  width: 41px;
  /* border-radius: 50%; */
}

.cart-per img {
  cursor: pointer;
  width: 30px;
  height: 40px;
}

.cash span {
  color: #ffae00;
}

.cash h4 {
  color: #fff;
  margin: 0;
}

.dec {
  margin: 0 !important;
}

.cash h4 span {
  color: #ffae00;
  font-size: 30px;
}

.social h2 {
  color: #ffae00;
  margin-bottom: 0;
}

.social .social-icon {
  column-gap: 10px;
}

.social .social-icon button {
  background-color: #000;
  border: none;
  outline: none;
  box-shadow: none !important;
  padding: 16px;
  display: flex;
  font-size: 20px;
  border-radius: 10px;
}

.home-margin {
  margin-top: 116px !important;
  margin: 0 auto;
}

/* cart */

.cart-main {
  margin-top: 116px;
  margin-left: 200px;
}

.cart-items.home-margin {
  margin-top: 0px !important;
}

.cart_style {
  height: 97vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 40px;
}

.cart-main .table_heading {
  border: none !important;
}

.table {
  margin-top: 120px;
}

.no-item {
  margin-top: 120px;
}

.table > thead > tr > th {
  white-space: nowrap;
  font-size: 20px;
}

.table > tbody > tr > td {
  font-size: 18px;
  font-weight: 600;
  border-bottom: 2px solid #f6f7f8 !important;
}

.product-detail-page {
  height: 100vh;
  /*  display: grid ;
    align-content: center;
    justify-content: initial ; */
}

.product-detail-page .offers-header {
  padding: 5rem 0 4rem 0;
}

.product-slider {
  margin: 0 !important;
}

/* .table>tbody>tr>td img {
    margin: 50px 0;
} */

.table > tbody {
  border-top: 2px solid #f6f7f8 !important;
}

.table > tbody > tr > td span {
  font-weight: bold;
  font-size: 18px;
  white-space: nowrap;
}

.table-row {
  vertical-align: middle;
}

.table-row .img-small {
  max-width: 200px;
  border-radius: 20px;
}

.close-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 7px;
  width: 25px;
  border: none;
  outline: none !important;
  box-shadow: none;
  height: 25px;
  border-radius: 50%;
  background-color: #ff687540;
  color: #ff4252;
  font-size: 17px;
  line-height: 19px;
}

.close-icon svg {
  margin-left: -2px;
}

.in-dc-btn {
  background-color: #f6f7f8;
  width: -moz-fit-content;
  width: fit-content;
  padding: 10px;
  border-radius: 5px;
  padding: 9px 18px;
  border-radius: 5px;
  grid-column-gap: 10px;
  column-gap: 17px;
}

.in-dc-btn span {
  font-weight: normal !important;
}

.in-dc-btn button {
  border: none;
  outline: none;
  box-shadow: none;
  background-color: transparent;
  font-size: 20px;
  color: #33a0ff;
  font-weight: bold;
}

/* modals */

.steps-main .step {
  background-color: #dfdede;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  text-align: center;
  line-height: 40px;
  z-index: 999;
}

.steps-main .step-by {
  position: relative;
}

.steps-main .step-by::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 2px;
  top: 50%;
  z-index: 1;
  background-color: #dfdede;
}

.steps-main .step.active {
  background-color: #ec7c24;
}

.steps-main .step span {
  color: #fff !important;
  font-size: 20px;
}

.modal-content {
  border-radius: 10px !important;
  padding: 20px 20px;
}

.modal-header {
  border: none !important;
}

.modal-header button {
  background-color: transparent !important;
  border: none;
  outline: none;
  position: absolute;
  left: auto;
  right: 12px;
}
.modal-header .backbtn {
  background-color: transparent !important;
  border: none;
  outline: none;
  position: absolute;
  left: 12px;
}

.modal-body span {
  color: #371a63;
  text-transform: capitalize;
  font-size: 24px;
  font-weight: bold;
}

.modal-body button {
  background-color: #ec7c24;
  padding: 15px 40px;
  color: #fff;
  font-weight: 600;
  border: none;
  outline: none;
  border-radius: 10px;
  margin-top: 50px !important;
}

.modal-header .close-modal {
  margin-left: auto;
}

.form input {
  background-color: #dfdede4d;
  border: none;
  padding: 10px;
  border-radius: 7px;
  width: 100%;
}

.card-img {
  width: 100%;
}

.form .save-credit {
  text-align: left;
}

.form .save-credit input {
  width: fit-content;
  height: 17px;
  vertical-align: middle;
  width: 17px;
}

.form .save-credit label {
  font-size: 14px;
  line-height: 21px;
}

.form .save-credit span {
  font-size: 16px;
  color: gray;
  font-weight: normal;
  margin-left: 10px;
}

.form .payment-method {
  text-align: left;
  margin-top: 20px;
}

.form .payment-method span {
  color: #371a63;
}

.form .payment-method .payment {
  padding: 15px 10px;
}

.form .payment-method .payment.active {
  background-color: rgb(224 224 224 / 32%) !important;
}

.form .payment-method .payment.active input::after {
  display: block;
}

.form .payment-method .payment span {
  color: #223263;
  font-size: 14px;
  margin-left: 10px;
}

.form .payment-method .payment input {
  width: fit-content;
  margin-left: auto;
  appearance: none;
  background-color: transparent;
  position: relative;
}

.form .payment-method .payment input::before {
  content: "";
  position: absolute;
  top: 0;
  width: 25px;
  height: 25px;
  right: 10px;
  border: 2px solid rgb(211, 211, 211);
  border-radius: 5px;
}

.form .payment-method .payment input::after {
  border: solid #371a63;
  border-width: 0 3px 3px 0;
  content: "";
  height: 13px;
  left: -5px;
  position: absolute;
  top: 4px;
  transform: rotate(45deg);
  width: 7px;
  display: none;
}

.form textarea {
  background-color: #dfdede4d;
  border: none;
  padding: 10px;
  height: 120px;
  border-radius: 7px;
  width: 100%;
}

/* signup */

.signup-main {
  margin-top: 116px;
  margin-left: 200px;
  height: calc(100vh - 117px);
  display: flex;
  justify-content: center;
}

.login-page {
  height: calc(100vh - 117px);
  display: flex;
  justify-content: center;
  margin-top: 116px !important;
  margin: 0 auto;
}

.profile-page {
  height: 100vh;
  display: flex;
  justify-content: center;
  /* margin-top: 116px !important; */
  margin: 0 auto;
}

.gender {
  position: relative;
  cursor: pointer;
}
.gender select {
  cursor: pointer;
}
.gender select option {
  cursor: pointer;
}
.gender img {
  position: absolute;
  left: auto;
  right: 18px;
  top: 50%;
  transform: translateY(11px);
}

.login {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 4rem;
}

.boxer-sign {
  /* max-width: 450px; */
  width: 100%;
  margin: 0 10px;
}

.box-signup {
  max-width: 450px;
  width: 100%;
}

.box-signup.box-profile {
  max-width: 450px;
  width: 100%;
}

.boxer-sign.boxer-profile {
  max-width: auto;
  width: 100%;
}

.box-signup .boxer-sign h1 {
  margin-top: 1rem !important;
}

.profile-img {
  display: flex;
  position: relative;
}

.profile-img img {
  border-radius: 50%;
  margin: 0 auto;
  width: 125px;
  height: 125px;
}

.box-signup .boxer-sign h1 {
  color: #381a64;
  font-weight: 600;
}

.box-signup .boxer-sign p {
  color: #878888;
  font-family: "Saira Condensed";
}

.box-signup .boxer-sign form {
  font-family: "Saira Condensed";
}

.box-signup .boxer-sign form .form-control {
  background-color: #e8e8e8 !important;
  padding: 14px 20px;
  margin-top: 2px;
}

.box-signup .boxer-sign form input::placeholder {
  color: #878888;
}

.box-signup .boxer-sign form label {
  font-weight: 600;
  font-size: 18px;
  color: #000;
}

.box-signup .boxer-sign form .btn-sign {
  background-color: #ec7c24;
  padding: 11px 0;
  font-weight: 500;
  font-size: 18px;
  font-family: "Saira Condensed";
}

.box-signup .boxer-sign .OROR {
  position: relative;
  width: 100%;
  height: 100%;
}

.box-signup .boxer-sign .or1,
.box-signup .boxer-sign .or2 {
  position: absolute;
  top: 50%;
  width: 45%;
  height: 100%;
  z-index: -1;
  border-top: 1px solid #cfcfcf;
}

.box-signup .boxer-sign .or1 {
  left: 0;
}

.box-signup .boxer-sign .or2 {
  right: 0;
}

.box-signup .boxer-sign .OROR .or-text {
  width: max-content;
  margin: 0 auto;
}

.box-signup .boxer-sign form .btn-fb {
  background-color: #3b5998 !important;
  padding: 8px 54px !important;
  font-family: "Saira Condensed";
  font-weight: 500;
}
.box-signup .boxer-sign form .btn-fb svg {
  font-size: 34px;
  margin-right: 8px;
}
/* for fb */
.box-signup .boxer-sign .btn-facebook {
  background-color: #3b5998 !important;
  padding: 8px 54px !important;
  font-family: "Saira Condensed";
  font-weight: 500;
}

.box-signup .boxer-sign .btn-facebook svg {
  font-size: 34px;
  margin-right: 8px;
}

.box-signup .boxer-sign .btn-facebook button {
  padding: 0;
  border: none;
  background-color: transparent;
  line-height: 1.5;
  font-size: 1rem;
  font-family: "Saira Condensed";
  font-weight: 500;
}
.box-signup .boxer-sign .pass-for {
  color: #000;
  text-align: right;
  font-weight: 600;
  cursor: pointer;
}

.map {
  /* max-height: 87vh; */
  height: 100%;
  position: relative;
  /* overflow: hidden; */
}

.offer-seller {
  position: absolute;
  left: 50%;
  top: 3%;
  transform: translateX(-50%);
  padding: 5px 6px;
  display: flex;
  justify-content: center;
  z-index: 1;
  background: #5500a5;
  box-shadow: 2px 10px 6px rgba(0, 0, 0, 0.45);
  border-radius: 40px;
}

.offsells {
  background: #8b0fff;
  border-radius: 40px;
}

.offsell-detail {
  cursor: pointer;
  padding: 2px 55px;
}

.offer-seller p {
  font-size: 32px;
  font-weight: 600;
  line-height: 50.37px;
  color: #fff;
  text-transform: uppercase;
  padding: 0;
  margin: 0;
}

.locateby-arrow {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}

/* .form-group select:focus > option:checked { 
    background: #000 !important;
} */

#map-style .outletmarker .gm-style .gm-style-iw-c {
  background-image: url(./assets/img/border.png) !important;
  background-color: transparent;
  box-shadow: none;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  padding: 0;
  left: 24px;
  top: 21px;
  border-radius: 20px;
  padding: 0px;
}
/* .gmnoprint {
    position: absolute;
    cursor: pointer;
    top: auto !important;
    left: 0px;
    bottom: 12px !important;
} */
#map-style .gm-style .gm-style-iw-c {
  background-image: url(./assets/img/border.png) !important;
  background-color: transparent;
  box-shadow: none;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center center;
  padding: 0;
  left: 27px;
  top: 18px;
  border-radius: 20px;
  padding: 0px;
}
#map-style .outletid .gm-style .gm-style-iw-c {
  background-image: url(./assets/img/border.png) !important;
  background-color: transparent;
  box-shadow: none;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center center;
  padding: 0;
  left: 22px;
  top: 18px;
  border-radius: 20px;
  padding: 0px;
}
#map-style .gm-style .gm-style-iw-d {
  overflow: auto !important;
  padding: 0px 4px 15px 2px !important;
}

.gm-style .gm-style-iw-t::after {
  display: none;
}

/* #map-style .gm-style .gm-style-iw-c{
    padding: 0;
    background: #fff;
    left: 25px;
    border: 3px solid #492D82;
    border-radius: 20px;
    padding: 10px 10px 0;
} */

#map-style .gm-style .gm-style-iw-c button {
  top: 0px !important;
  right: 0px !important;
}

#map-style .product-per {
  justify-content: center !important;
  flex-wrap: wrap;
}

#map-style .gm-ui-hover-effect {
  display: none !important;
}

.product-img .items-img {
  display: flex !important;
  flex-direction: row !important;
  justify-content: center;
  box-sizing: border-box;
  cursor: pointer;
}

/* .product-img .items-img{
    justify-content: center;
} */

.product-img .items-img img {
  max-width: 130px;
}

.product-img .items-img img {
  /* height: 53px;
    max-width: 60px; */
  height: 73px;
  max-width: 77px;
  width: 100%;
  padding: 0;
}

.product-img .items-img .offerlogo {
  height: 47.81px;
  max-width: 47.81px;
  width: 100%;
  margin: 9px 0 0 0px;
  padding: 0;
}
.product-img .items-img .specialOffer {
  margin: 9px 0 0 7px !important;
}

.product-img .item-amount {
  text-align: center;
}

.product-img .item-amount p {
  font-weight: 600 !important;
  text-align: center;
  margin: 0 auto;
  padding: 0 5px;
  font-size: 17.37px;
  line-height: 27.34px;
  color: #575757;
  max-width: 84px;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.product-img .item-amount span {
  font-weight: 700 !important;
  font-size: 30.37px;
  line-height: 50.78px;
  color: #492d82 !important;
}

.map-style .home-margin {
  margin-top: 52px !important;
}

.social .insta-app {
  max-height: 335px;
  height: auto;
}
/* .insta-app{
    max-width: 185px;
    width: 100%;
    max-height: 387px;
    height: auto;
} */

/* .socialDownload{
    height: calc(100vh - 362px);
} */
.map-detail-style {
  height: calc(100vh - 126px);
}
.socialDownload {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: calc(100vh - 361px);
}
/***** Modal *****/

.modal-backdrop {
  display: none;
}

.shoping-def .modal-dialog {
  position: absolute;
  width: 100%;
  left: 5rem;
  top: 5.6rem;
}

.shoping-def .modal-dialog.tag-dialog {
  left: 17.55rem;
}

/***** End Modal *****/

/* product-detail */

.product-detail {
  margin-left: 200px;
  margin-top: 116px;
}

.product-detail-mini {
  margin-top: 116px;
  margin-left: 0;
}

.product-detail-mini .slick-track {
  display: flex;
  align-items: center;
}
.p_container {
  max-width: 1572px;
  width: 100%;
  margin: 0 auto;
}
.product_text {
  margin-top: 97px;
  font-size: 18px;
  font-weight: 300;
  font-style: normal;
  line-height: 25px;
  color: #000;
  /* padding-left: 7rem; */
}
.slick-initialized .slick-slide {
  margin: 0 5px;
}

.slider-img {
  max-width: 500px;
  margin: 0 auto;
  display: block !important;
  outline: none;
}

.iconer {
  max-width: 450px;
  margin: 0 auto;
  padding-top: 15px;
}

.slider-img img {
  width: 100%;
  /* height: 100%; */
  height: 520px;
  object-fit: cover;
  margin: 0 auto;
  border-radius: 20px;
  outline: none;
}

img .slide .svgicon,
.slide .svgicon1 {
  max-width: 50px;
}

/* slick slider start */

.slick-dots {
  bottom: -45px !important;
}

.slick-arrow {
  display: none !important;
}

.slick-dots li button:before {
  font-size: 15px !important;
}

.slick-dots li.slick-active button:before {
  opacity: 1 !important;
  color: #4a4086 !important;
}

/* slick slider end */

.whopp h1 {
  color: #492d82;
  font-weight: bold;
  font-size: 52px;
  margin-bottom: 20px;
}

.whopp h5 {
  color: #332a6e;
  font-size: 21px;
}

.whopp .p-text {
  font-family: "Saira Condensed";
  font-size: 18px;
  line-height: 1.4;
  color: #000;
}

.whopp .npm h1,
p {
  color: #92928d;
  line-height: 1.1;
}

.whopp .npm-main {
  max-width: 390px;
}

.whopp .npm2 p {
  color: #332a6e;
}

.whopp .cart-btn {
  background-color: #ec7c24;
  border-radius: 35px;
  outline: none;
  border: none;
  font-size: 19px;
  padding: 9px 60px;
  color: white;
  font-family: "Saira Condensed";
}

/* //readmore */

.read-text {
  width: 100%;
}

.read-or-hide {
  color: rgb(192, 192, 192);
  cursor: pointer;
}

.area-mobile-modal {
  display: none;
}

.seller-mobile-modal {
  display: none;
}

.tag-mobile-modal {
  display: none;
}

/* //tag checkBtn */
.tag-select {
  font-size: 12px;
  line-height: 11.35px;
  text-transform: uppercase;
  display: inline-block;
  font-weight: 600;
  cursor: pointer;
  border-radius: 12px;
  padding: 8px 21px;
  margin: 9.5px 3.72px 0 !important;
  background-color: #e8e8e8;
  color: #828282;
}

.tag-select:hover {
  background: #d0d3d4;
}

input:checked + label > tag-select {
  background: #828282;
  color: #e8e8e8;
}

input:checked + label > .tag-select {
  background: #828282;
  color: #e8e8e8;
}
.btnTag input {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -33%);
  height: 25px;
  opacity: 0;
}
#category {
  position: relative;
}
#category input {
  position: absolute;
  height: -webkit-fill-available;
  /* display: none; */
  opacity: 0;
  max-width: 100%;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}
.area-user {
  position: relative;
}
.area-user input {
  position: absolute;
  /* display: none; */
  opacity: 0;
  max-width: 100%;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  height: 100%;
}
.seller_content {
  position: relative;
}
.seller_content input {
  position: absolute;
  /* display: none; */
  opacity: 0;
  max-width: 100%;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  height: 100%;
}

/* Tabs */

.profile-tabs{
  margin-top: 9rem ;
}
.profile-tabs h1{
  color: #381A64;
  text-transform: uppercase;
  font-weight: 700;
font-size: 36px;

line-height: 56.66px;
}
.profile-tabs .profile-page{
  height: auto;
}
.profile-tabs .nav-link{
  color: #878888;
  font-size: 20px;
  font-weight: 700;
}
.profile-tabs .nav.nav-tabs{
  justify-content: center;
  background: #ECECEC;
  max-width: 890px;
  width: 100%;
  margin: 0 auto;
border-radius: 15px;
}
.profile-tabs .nav-tabs .nav-link{
  padding: 18px 1rem;
}
.profile-tabs .nav-tabs, .profile-tabs .nav-tabs .nav-link{
  border: none;
}
.profile-tabs .nav-tabs .nav-item.show .nav-link, .profile-tabs .nav-tabs .nav-link.active, .profile-tabs .nav-link:focus, .profile-tabs .nav-link:hover{
  border: none;
  color: #5500A5;
  background-color: transparent;
}

.profile-tabs .nav.nav-tabs.profile-nest-tabs{
  background-color: transparent;
}
.profile-tabs .profile-nest-tabs .nav-link{
  font-weight: 400;
  padding: 0;
  margin: 0 1rem;
}
.profile-tabs .profile-nest-tabs .nav-link.active{
  border-bottom: 1px solid #5500a5;
}

/* deal */
.offers-main.deal-catalog .deal-voucher{
  justify-content: flex-start;
}
.offers-main.deal-catalog .deal-voucher .deal-header{
  margin-right: 80px;
}
.deal-catalog .profile-tabs{
  margin: 0;
}
.deal-catalog .profile-tabs .nav.nav-tabs{
  background: transparent;
  justify-content: flex-start;
  margin: 0;
}
.deal-catalog .cash, .deal-catalog .btnTag input{
  cursor: pointer;
}
.deal-catalog .cash span{
  justify-content: center;
}
.deal-catalog .cash h4.points{
  display: flex;
    justify-content: center;
    align-items: center;
}
.deal-catalog .cash h4.points span{
  margin-left: 8px;
  font-size: 24px;
}
.deal-catalog .nav-tabs img.catalog-img{
      width: 25px;
    height: 25px;   
    margin-bottom: 4px;
}
.deal-catalog .nav-tabs img{
  width: 35px;
  margin-bottom: 4px;
}
.deal-catalog .voucher-box{
  max-width: 167px;
  padding: 0;
  width: 100%;
  cursor: pointer;
}
.cursor-pointer{
  cursor: pointer;
}
.deal-catalog .main-deal::-webkit-scrollbar{
  /* height: 5px; */
}
.deal-catalog .main-deal{
  overflow: hidden;
  overflow-x: scroll;
}
.deal-catalog .deal-box{
  display: flex;
  flex-direction: row;
  margin-right: 15px;
}
.deal-catalog .deal-tags{
  justify-content: flex-start;
}
.deal-catalog .deal-tags{
 max-width: 1000px;
 width: 100%;
}
.deal-catalog p.read-text,.deal-catalog .foodlist{
  margin-bottom: 0;
}
.deal-catalog .foodlist{
  justify-content: center;
  margin-top: 10px;
}
.deal-catalog .foodlist li{
  list-style: disc;
  font-size: 16px;
  font-weight: 600;
}
.deal-catalog .discountamount h5:first-child{
margin-right: 15px;
font-weight: bolder;
}
.deal-catalog .discountamount .discount{
  color: #009944;
  font-weight: bolder;
}
.deal-catalog .left-vouchers{
  border-right: none;
  padding: 0;
  cursor: auto;
}
.deal-catalog .voucher-text.col-lg-6{
  width: 64%;
}
.deal-catalog .left-vouchers .row{
  margin: 0;
  padding: 0 0 10px;
  max-width: 1000px;
  width: 100%;
  border-bottom: .72px solid #D0D0D0;
}
.deal-catalog .voucher-btn{
  margin: 0;
  display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-end;
}
.deal-catalog .voucher-box .v-img{
  height: 130px;
}
.deal-catalog .priceset h6{
  margin: 0;
  text-align: end;
  font-size: 18px;
  color: #492D82;
}
.deal-catalog .priceset h1{
  text-align: end;
}
.deal-catalog .voucher-btn button{
  text-align: center;
  max-width: 70.06px;
  width: 100%;
}

/* reward-main */
.reward-main .reworddesc{
  line-height: 27px;
  font-size: 27px;
  text-align: start;
}
.reward-main .discount-img-box{
  border-right: 2px dashed #5500a5;
}

/* <-------------Mithil----------------> */
img {
  max-width: 100%;
  height: auto;
}
.box {
  display: flex;
  justify-content: center;
  align-items: center;
}
.reword {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 25px;
  border-radius: 30px;
  margin-top: 12px;
  text-align: center;
  height: 39px;
  background:#FFCE50;
  color:#5500A5
}
.reword.reword-bonus{
  background:#5500A5;
  color:#fff
}
.reword.reword-bonus h3{
  color: #fff;
}
.reword h2,
.reword h3 {
  margin-bottom: 0;
}
.bonusimg img {
  width: 100%;
}
.reword h2,
.reword h3 {
  font-size: 20px !important;
}
.reworddesc {
  color: #5500a5;
  text-align: center;
}
.searchinput {
      width: 100%;
  background-color: #d7d7d7;
  color: #525F6F;
    border-radius: 30px;
    padding: 8px 15px;
  border: none;
  font-size: 18px;
}
.searchinput[type="text"]::placeholder {
  text-align: center;
  /* font-size: 20px;
  line-height: 20px; */
}
.rewordoption {
  text-align: center;    
  display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}
.rewordoption h5 {
  color: #5500a5;
  font-weight: bold;
  font-size: 18px;
  margin-top: 10px;
}
.bonus-point h4 {
  color: #5500a5;
  text-align: center;
  font-weight: bold;
}
.bonus-point h3 {
  color: #5500a5;
  text-align: center;
  font-weight: 600;
}
.offerimg {
  height: 310px !important;
}
.imgfoodlist {
  max-width: 300px;
  width: 100%;
  margin-bottom: 10px;
}
.krprice {
  font-size: 45px;
  color: #492d82;
  font-weight: bold;
  margin: 0;
}
.buybtn {
  font-size: 20px;
  color: white;
  font-weight: lighter;
  background-color: #ec7c24;
  text-align: center;
  padding: 10px 30px;
  border-radius: 30px;
  text-decoration: none;
}
.foodlist {
  display: flex;
  list-style: none;
}
.foodlist li {
  margin: 0 20px;
  font-size: 20px;
  color: #5500a5;
  font-weight: bold;
}
.discountamount {
  display: flex;
}
.discountamount h2 {
  font-size: 35px;
  font-weight: 800;
}
.discount {
  color: #009944;
  margin-left: 50px;
}


/***** Media Queries *****/
@media (min-width: 1400px) and (max-width: 1670px) {
  .share-btn img {
    margin-right: 5px;
    max-width: 77px;
  }
}

@media (max-width: 1500px) {
  .offers-main.deal-catalog .deal-voucher{
    justify-content: space-between;
  }
  .offers-main.deal-catalog .deal-voucher .deal-header{
  margin-right: 0px;
}
}
@media (max-width: 1415px) {
  .deal-catalog .voucher-btn{

    flex-direction: row;
    justify-content: space-between;
  }
  .deal-catalog .voucher-btn.col-lg-2 {
    width: 100%;
  }
  .deal-catalog .voucher-text.col-lg-6{
    width: 70%;
  }
  .reword h2, .reword h3 {
    font-size: 15px !important;
}
.reword .box img{
  width: 25px;
}
}
@media (max-width: 1282px) {
  .svgicon,
  .svgicon1 {
    max-width: 40px !important;
  }
  /* 1200px */
  .vouchers .row .d-flex {
    flex-direction: column;
  }
  .deal-catalog .vouchers .row .d-flex {
    flex-direction: row;
  }
  .deal-catalog .vouchers .voucher-btn{
    justify-content: space-between;
  }
  .vouchers .share-btn {
    flex-direction: row !important;
  }
}
/* @media (max-width:1160px) {
.product_text{
    padding-left: 0px;
}
} */
@media (max-width: 1145px) {
  .deal-header h4 {
    font-size: 22px;
  }
}
@media (max-width: 1024px) {
  .product-detail {
    margin-left: 0;
  }
  .main-content {
    margin-left: 0;
    column-count: 3;
    column-gap: 10px;
  }
  .offers-main {
    margin-left: 0;
    width: 100%;
  }
  .voucher-text {
    margin-top: 10px;
  }
  .voucher-list {
    margin-right: 24px;
  }
  .signup-main {
    margin-left: 0;
  }
  .area-mobile-modal {
    display: block;
  }
  .seller-mobile-modal {
    display: block;
  }
  .tag-mobile-modal {
    display: block;
  }
}

@media (max-width: 1000px) and (orientation: landscape) {
  .home-margin {
    /* margin-top: 67px !important; */
    margin: 0 auto;
  }
  .box-signup.box-profile {
    margin-top: 22rem;
  }
}

@media (min-width: 1024px) and (max-height: 800px) {
  .boxer-sign.boxer-profile {
    margin-top: 13rem !important;
  }
}

@media (min-width: 1000px) and (max-height: 781px) {
  .social .insta-app {
    display: none;
  }
  .social .insta-app {
    max-height: 213px !important;
    height: auto;
  }
}
@media (max-width: 915px) and (max-height: 1024px) {
  .home-margin.map-style {
    margin-top: 67px !important;
    margin: 0 auto;
  }
}
@media screen and (max-height: 885px) and (min-height: 799px) {
  .social .insta-app {
    max-height: 217px;
    height: auto;
  }
}
@media (max-width: 576px) and (max-height: 700px) {
  .price {
    overflow: auto;
    max-height: 245px;
    margin-top: 20px;
  }
}
@media (max-width: 991px) {
  .product-detail-mini {
    margin-top: 67px;
  }
  .whopp {
    margin: 40px 0px 20px 0;
  }
  .offers-main {
    padding: 1rem !important;
  }
  .offers-main .row > div {
    padding: 0 6px;
    margin: 0;
  }
  .home-margin.map-style {
    padding: 0 !important;
  }
  .product-detail-page .offers-header {
    padding: 29rem 0 2rem 0;
  }
  .main-content {
    margin-left: 0;
    column-count: 2;
    column-gap: 10px;
  }
  .sidebar {
    margin-top: 115px;
  }
  .navbar .shoping-def {
    display: none;
  }
  .navbar .user {
    display: none !important;
  }
  .navbar .logo img {
    max-width: 200px;
  }
  .navbar .right-toggle {
    margin-right: 10px;
    display: block !important;
  }
  .phone-search {
    display: block !important;
  }
  .social {
    margin-bottom: 1rem;
    /* height: calc(100vh - 362px); */
  }
  .socialDownload {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: auto;
  }
  .navbar {
    flex-direction: column;
  }
  .slider-img img {
    height: auto;
  }
  .left-vouchers {
    border: none;
  }
  .voucher-code {
    margin-left: 0;
  }
  .map {
    height: 500px !important;
  }
  .map-detail-style {
    height: auto;
  }
  .offers-right {
    margin-top: 20px;
  }
  .login-page {
    height: 100%;
  }
  .signup-main {
    height: 100% !important;
  }
  .profile-tabs {
    margin-top: 5rem;
}
.profile-tabs .nav-link{
  font-size: 17px;
}
.nav-tabs::-webkit-scrollbar, .nav-tabs::-webkit-scrollbar-thumb{
  display: none;
}
.profile-tabs .nav.nav-tabs.profile-nest-tabs{
  justify-content: center;
}
.profile-tabs .nav.nav-tabs{
  justify-content: unset;
  max-width: 760px;
  width: auto;
  overflow: hidden;
  overflow-x: scroll;
  flex-wrap: nowrap;
}
.profile-tabs .profile-nest-tabs .nav-link{
  font-size: 16px;
}
.profile-tabs .nav-tabs .nav-link{
  width: max-content;
}
.profile-tabs .box-signup.box-profile{
  margin-top: 0;
}
.discountamount h2 {
        font-size: 20px;
        text-align: center;
    }
    .foodlist li {
      font-size: 16px;
      padding: 0;
  }
  .priceset{
        display: flex;
        align-items: center;
    }
    .priceset h1{
        margin-left:6px;
    }
    .imgfoodlist{
        margin:10px auto;
        display: block;
    }
}

@media (max-width: 768px) {
  .voucher-code {
    margin: 0 auto !important;
  }
  .voucher-list {
    margin: 0 auto;
  }
  .profile-tabs .nav.nav-tabs{
    margin: 0 12px;
  }
  .reward-main .reworddesc{
    margin: 10px 0;
  }
  .reward-main.offers-main .row > div{
    padding-right: calc(var(--bs-gutter-x) * .5);
    padding-left: calc(var(--bs-gutter-x) * .5);
  }
  .reward-main .discount-img-box{
  border-right:none;
}
.reward-main .reworddesc br{
  display: none;
}
}

@media (max-width: 600px) {
    
    .foodlist{
        padding: 0;
    }
    .foodlist li {
        font-size: 14px;
        padding: 0;
    }
    .discountamount{
        justify-content: flex-start;
    }
    
    
    .discount{
        font-size: 20px;
    }
    .krprice {
        font-size: 30px;
    }
    .buybtn{
        padding: 5px 20px;
        width: 100%;
        display: block;
    }
}
@media (max-width: 630px) {
  .seller_content .content_detail .address {
    max-width: 300px;
  }
  .deal-header h4 {
    font-size: 19px;
  }
  .active-img {
    width: 23px;
  }
}

@media (max-width: 590px) {
  .rowper {
    justify-content: center !important;
  }
  .deal-catalog .voucher-box{
    max-width: 100%;
  }
  .deal-catalog .voucher-text.col-lg-6{
    width: 100%;
  }
  .deal-catalog .foodlist,.deal-catalog .discountamount{
    margin-top: 8px;
  }
  .deal-catalog .foodlist li{
    font-size: 13px;
  }
  
  .reward-main .food-ad-img{
    height: 150px;
  }
}

@media (max-width: 539px) {
  .profile-tabs .nav.nav-tabs.profile-nest-tabs{
  justify-content: unset;
}
.profile-tabs h1{
  font-size: 33px;
}
  #loadSpin {
    top: 45%;
    left: 43% !important;
  }
  .offsell-detail {
    padding: 2px 30px;
  }
  .offer-seller p {
    font-size: 20px;
    line-height: 37.37px;
  }
  .cart_style {
    display: block;
  }
  .voucher-code {
    width: 100%;
    height: auto;
  }
  .table-responsive {
    margin-top: -4rem;
  }
  .voucher-code input {
    height: 45px !important;
  }
  .voucher-code button {
    height: 45px !important;
  }
  .home-margin.map-style {
    margin-top: 65px !important;
  }
  .signUp-style.login-page {
    margin-top: 30px !important;
  }
  #m-area .modal-dialog .modal-content {
    height: calc(100vh - 60px);
  }
  .deal-header h4 {
    font-size: 13px;
    line-height: 16px;
  }
}

@media (max-width: 450px) {
  .seller_content .content_detail .address {
    max-width: 160px;
  }
  .svgicon,
  .svgicon1 {
    max-width: 30px !important;
  }
  .svgicon1 {
    max-width: 22px !important;
  }
  .iconer {
    padding-top: 14px;
  }
  .slick-dots {
    bottom: -40px !important;
  }
  .whopp h1 {
    font-size: 45px;
  }
}

@media (max-width: 425px) {
  .spinner-border {
    position: absolute;
    left: 45%;
    top: 50%;
  }
  .main-content {
    margin-left: 0;
    column-count: 1;
    column-gap: 10px;
  }
  .left-vouchers {
    padding: 0;
  }
  .offers-right {
    margin-top: 20px;
  }
  .voucher-code {
    flex-direction: column;
    height: auto;
  }
  .voucher-code button {
    width: 100%;
  }
  .form {
    margin-top: 20px;
  }
}

@media (max-width: 375px) {
  .profile-setting {
    margin-top: 3rem;
  }
  .profile-tabs .profile-setting {
    margin-top: 1rem;
}
  .svgicon,
  .svgicon1 {
    max-width: 27px !important;
  }
  .svgicon1 {
    max-width: 20px !important;
  }
  .slick-dots {
    bottom: -39px !important;
  }
}

@media (max-width: 360px) {
  .product-detail-page .offers-header {
    padding: 28rem 0 2rem 0;
  }
  .profile-page {
    height: 100vh;
    align-items: center;
    display: flex;
    justify-content: center;
    margin-top: 15px !important;
  }
  .login {
    padding-top: 5rem;
  }
}

@media (max-width: 320px) {
  .box-signup .boxer-sign form .btn-fb {
    padding: 8px 38px;
  }

  .box-signup .boxer-sign .btn-facebook {
    padding: 8px 38px;
  }
  .product-detail-page .offers-header {
    padding: 30rem 0 2rem 0;
  }
  .profile-page {
    height: 100vh;
    align-items: center;
    display: flex;
    justify-content: center;
    margin-top: 71px !important;
  }
}

/***** End Media Queries *****/



